import * as React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../styles/global.css'
import Seo from '../../components/Seo'

// Context lets us pass a value deep into the component tree
// without explicitly threading it through every component.
// Create a context for the current theme (with "light" as the default).
export const LanguageContext = React.createContext('English');
export const ThemeContext = React.createContext('Light');

// markup
const TermsPage = () => {
  return (
    <ThemeContext.Provider value="Dark">
      <LanguageContext.Provider value={"English"}>
        <main style={{ backgroundColor: "#333" }}>
          <Seo
            title='Matthe815.dev'
          />

          <p style={{ color: "white", display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column", alignItems: "center", flexWrap: "wrap", textAlign: "center" }}>
            Privacy Policy for Starie
            Last Updated: 11/29/2024
            <br/><br/>
            1. Introduction Starie (the “Bot”) values your privacy. This Privacy Policy explains how we collect, use, and protect your data when you interact with the Bot. By using or interacting with the Bot, you consent to the practices described in this policy.
            <br/><br/>
            2. Information We Collect We may collect the following types of information:

            User Data: Basic user interactions such as messages, commands, and responses within the Discord servers where the Bot is active.
            Server Information: Data related to the server, including server name, ID, and member roles.
            Telemetry Data: Technical information used to analyze and improve the Bot’s performance and functionality.
            <br/><br/>
            3. How We Use Your Information We use the information we collect for the following purposes:

            To provide and maintain the Bot’s services.
            To improve the Bot’s functionality, fix bugs, and enhance performance.
            To analyze how the Bot is being used through anonymized analytics data.
            <br/><br/>
            4. Telemetry and Analytics Starie may collect telemetry data for the following reasons:

            Performance Monitoring: To understand usage patterns, identify potential issues, and optimize the Bot’s performance.
            Error Reporting: To help us identify and fix bugs or other technical issues.
            Feature Usage Analysis: To evaluate which features are popular and how the Bot is being interacted with.
            Telemetry data may include but is not limited to:

            Command usage statistics.
            Interaction times and durations.
            Error logs and crash reports.
            <br/><br/>
            5. Data Retention We retain data only as long as necessary to provide the services or for legitimate operational purposes. Data used for analytics and telemetry may be retained in an anonymized form for longer periods to track performance trends.
            <br/><br/>
            6. Data Security We take reasonable steps to protect your data using industry-standard security measures. However, we cannot guarantee absolute security, as no data transmission over the internet can be 100% secure.
            <br/><br/>
            7. Sharing and Disclosure We do not share your personal data with third parties, except in the following cases:

            When required by law or to comply with legal obligations.
            To ensure the safety, security, and integrity of the Bot or other users.
            With trusted third-party service providers who help us operate and improve the Bot (e.g., for telemetry analytics, but always in a way that protects your privacy).
            8. Your Rights Depending on your location, you may have rights under applicable data protection laws, including the right to:

            Access, update, or delete your data.
            Object to or restrict certain types of data processing.
            Request data portability.
            <br/><br/>
            9. Changes to This Privacy Policy We may update this Privacy Policy from time to time. Any changes will be reflected on this page with an updated “Last Updated” date. We encourage you to review this Privacy Policy periodically to stay informed about how we collect, use, and protect your data.
            <br/><br/>
            10. Acknowledgement By using Starie, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.
          </p>

        </main>
      </LanguageContext.Provider>
    </ThemeContext.Provider>
  )
}

export default TermsPage
